import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  SKILLS_LIST_REQUEST_PB,
  SkillsListRequest,
} from "@skydio/channels/src/skills_list_request_pb";
import { UPDATE_CAPTURE_SETTINGS_PB } from "@skydio/channels/src/update_capture_settings_pb";
import { G47CameraStatus } from "@skydio/pbtypes/pbtypes/dock/dock_core/g47_camera_pb";
import { ClientType } from "@skydio/pbtypes/pbtypes/gen/cloud_config/client_type_pb";
import { PilotRequest } from "@skydio/pbtypes/pbtypes/vehicle/pilot_service/pilot_pb";
import { ProtocolChannelDefinitions } from "@skydio/skybus/src/transport/livekit/livekit_transport_layer";
import { RemoteDeviceType } from "@skydio/skybus/src/transport/types";
import { QOS } from "@skydio/skybus/src/types";

import { utimeNow } from "utils/time_manager";

import { ALERT_TYPES } from "./alertDefinitions";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Ping } from "@skydio/channels/src/device_node_ping_from_server_pb";
import type { DockClientStatus } from "@skydio/channels/src/dock_client_status_pb";
import type { G47LedState } from "@skydio/channels/src/g47_led_state_pb";
import type { GroundhawkG47MotorStatuses } from "@skydio/channels/src/groundhawk_g47_motor_statuses_pb";
import type { QcaStatsLite } from "@skydio/channels/src/qca_stats_lite_pb";
import type { RelocalizationInfo } from "@skydio/channels/src/relocalization_info_pb";
import type { SkillsList } from "@skydio/channels/src/skills_list_pb";
import type { SignalStats } from "@skydio/channels/src/telit_lte_stats_pb";
import type { CaptureSettings } from "@skydio/channels/src/update_capture_settings_pb";
import type { IrCaptureSettings } from "@skydio/channels/src/update_ir_capture_settings_pb";
import type { WifiStatus } from "@skydio/channels/src/vehicle_wifi_status_pb";
import type { WeatherStationData } from "@skydio/channels/src/weather_station_data_pb";
import type { G47MotorStatus } from "@skydio/pbtypes/pbtypes/gen/dock/g47_motor_status_pb";
import type { PilotResponse } from "@skydio/pbtypes/pbtypes/vehicle/pilot_service/pilot_pb";
import type UnifiedSkybus from "@skydio/skybus/src/unified";
import type { AlertId } from "./alertDefinitions";

export type SettingsModalTabsKey =
  | "flightControls"
  | "return"
  | "sensing"
  | "lighting"
  | "attachments"
  | "adsb"
  | "networkTesting"
  | "faults"
  | "memory"
  | "autonomousFlight"
  | "connectivity"
  | "display"
  | "sharing";

export const SETTINGS_MODAL_DEFAULT_TAB = "flightControls";

export type SkybusSkillListRequestState =
  | {
      status: "idle";
    }
  | {
      status: "inflight";
      startTime: number;
    }
  | {
      status: "success";
      data: SkillsList.AsObject;
    };
export type SkybusPilotRequestState =
  | {
      status: "idle";
    }
  | {
      status: "inflight";
      clientId: string;
      startTime: number;
    }
  | {
      status: "success";
      data: PilotResponse.AsObject;
    }
  | {
      status: "error";
      error: string;
    };
export type FlightDeckPilotRequestState =
  | {
      status: "error";
      error: string;
    }
  | {
      status: "idle";
    }
  | {
      status: "success";
    }
  | {
      status: "inflight";
    };
export type CaptureSettingsRequestState =
  | {
      status: "idle";
    }
  | {
      status: "inflight";
    }
  | {
      status: "success";
    }
  | {
      status: "error";
      error: string;
    };

export type SkybusPilotRequestAction = {
  skybus: UnifiedSkybus;
  clientId: string;
  deviceType: RemoteDeviceType;
  options?: { commandeer?: boolean };
};

export type SkybusPilotResponseErrorAction = {
  error: string;
  deviceType: RemoteDeviceType;
};

export type SkybusPilotResponseAction = {
  pilotResponse: PilotResponse.AsObject;
  deviceType: RemoteDeviceType;
};

export type SkybusUpdateCaptureSettingsAction = {
  skybus: UnifiedSkybus;
  captureSettings: CaptureSettings;
};

export type AlertAction = {
  label: string;
  onClick: () => void;
  variant?: "primary" | "default" | "light" | "link" | "neutralLink";
  size?: "sm" | "md" | "lg";
};

export type AlertInstance = {
  id: AlertId;
  alertVariant?: string;
  createdAt: string;
  mutedAt?: string | null;
  dismissedAt?: string | null;
  vehicleId: string;
  audioPlayedAt?: string[];
  actions?: AlertAction[];
};

export interface TeleopState {
  wantsAutoPrep: boolean;
  // skill name to enter teleop with if cloud wants to overrule the vehicle default based on UI.
  skillOverride?: string;
  teleopSessionId: string;
  skillsListRequest: SkybusSkillListRequestState;
  captureSettingsRequest: CaptureSettingsRequestState;
  captureSettings?: CaptureSettings.AsObject;
  irCaptureSettings?: IrCaptureSettings;
  pilotRequest: {
    skybus: SkybusPilotRequestState;
    skybusRetriesLeft: number;
    skybusDock: SkybusPilotRequestState;
    skybusDockRetriesLeft: number;
    flightDeck: FlightDeckPilotRequestState;
    hasTriedToRequestPilot: boolean;
    commandeer?: boolean;
  };
  qcaStats?: QcaStatsLite.AsObject;
  qcaStatsLastUpdated?: Date;
  relocalizationInfo?: RelocalizationInfo.AsObject;
  dockClientStatus?: DockClientStatus.AsObject;
  g47MotorState?: G47MotorStatus.AsObject;
  g47LedState?: G47LedState.AsObject;
  groundhawkG47MotorStatuses?: GroundhawkG47MotorStatuses.AsObject;
  vehicleSid: string;
  vehicleInRoom: boolean;
  dockSid: string;
  dockInRoom: boolean;
  dockCameraStatus: G47CameraStatus;
  weatherStationData?: WeatherStationData.AsObject;
  weatherStationDataLastUpdated?: Date;
  alertTypes: typeof ALERT_TYPES;
  activeAlerts: { [name in AlertId]?: AlertInstance };
  lteStats?: SignalStats.AsObject;
  isAlertManagerDisplayActive: boolean;
  showControlsModal: boolean;
  showSettingsModal: boolean;
  settingsModalActiveTab: SettingsModalTabsKey;
  settingsPreviewDismissed: boolean;
  vehicleWifiStatus?: WifiStatus.AsObject;
  selectedEntity: string | null;
  reportedPageNavigationType?: string;
  showMarkersSidebar?: boolean;
  showPerFlightFeedback: boolean;
  latestDeviceNodePingMessage?: Ping;
  lastTenDeviceNodeRTTs: number[];
  showParachuteModal: boolean;
  numParachutePresses: number;
  allowChannelConflictAlert: boolean;
  hasChannelConflict: boolean;
  currentOtherUsedSiteChannels: number[];
}

const initialState: TeleopState = {
  wantsAutoPrep: false,
  teleopSessionId: "",
  skillsListRequest: { status: "idle" },
  captureSettingsRequest: { status: "idle" },
  pilotRequest: {
    skybus: { status: "idle" },
    skybusRetriesLeft: 0,
    skybusDock: { status: "idle" },
    skybusDockRetriesLeft: 0,
    flightDeck: { status: "idle" },
    hasTriedToRequestPilot: false,
  },
  vehicleSid: "",
  vehicleInRoom: false,
  dockSid: "",
  dockInRoom: false,
  dockCameraStatus: new G47CameraStatus(),
  alertTypes: ALERT_TYPES,
  activeAlerts: {},
  /**
   * Flag to indicate if the alert manager display (which handles the audible alerts) is active
   */
  isAlertManagerDisplayActive: false,
  showControlsModal: false,
  showSettingsModal: false,
  settingsPreviewDismissed: false,
  selectedEntity: null,
  settingsModalActiveTab: SETTINGS_MODAL_DEFAULT_TAB,
  showPerFlightFeedback: false,
  lastTenDeviceNodeRTTs: [],
  showParachuteModal: false,
  numParachutePresses: 0,
  allowChannelConflictAlert: true,
  hasChannelConflict: false,
  currentOtherUsedSiteChannels: [],
};

const { actions, reducer } = createSlice({
  name: "currentTeleopSession",
  initialState,
  reducers: {
    updateTeleopSessionId(state, { payload }: PayloadAction<string>) {
      state.pilotRequest = { ...initialState.pilotRequest };
      state.teleopSessionId = payload;
    },
    clearSkillsList(state) {
      state.skillsListRequest = { status: "idle" };
    },
    requestSkillsList(state, { payload: skybus }: PayloadAction<UnifiedSkybus>) {
      state.skillsListRequest = {
        status: "inflight",
        startTime: Date.now(),
      };

      const request = new SkillsListRequest();
      request.setUtime(utimeNow());
      skybus.publish(SKILLS_LIST_REQUEST_PB, request);
    },
    handleSkillsList(state, { payload: skillsList }: PayloadAction<SkillsList.AsObject>) {
      state.skillsListRequest = { status: "success", data: skillsList };
    },
    clearPilotRequestResults(state) {
      // only clear requests that have a result, ie. are not currently in flight
      if (state.pilotRequest.skybus.status !== "inflight") {
        state.pilotRequest.skybus = { status: "idle" };
      }
      if (state.pilotRequest.flightDeck.status !== "inflight") {
        state.pilotRequest.flightDeck = { status: "idle" };
      }
    },
    setRequestPilotRetriesLeft(
      state,
      { payload }: PayloadAction<{ deviceType: RemoteDeviceType; retriesLeft: number }>
    ) {
      if (payload.deviceType === RemoteDeviceType.DOCK) {
        state.pilotRequest.skybusDockRetriesLeft = payload.retriesLeft;
      } else {
        state.pilotRequest.skybusRetriesLeft = payload.retriesLeft;
      }
    },
    decrementRequestPilotRetriesLeft(state, { payload }: PayloadAction<RemoteDeviceType>) {
      if (payload === RemoteDeviceType.DOCK) {
        state.pilotRequest.skybusDockRetriesLeft -= 1;
      } else {
        state.pilotRequest.skybusRetriesLeft -= 1;
      }
    },
    setCommandeer(state, { payload }: PayloadAction<boolean>) {
      state.pilotRequest.commandeer = payload;
    },
    requestPilot(state, { payload }: PayloadAction<SkybusPilotRequestAction>) {
      const { skybus, clientId, options, deviceType } = payload;
      const { commandeer = false } = options ?? {};
      const action = commandeer ? PilotRequest.Action.COMMANDEER : PilotRequest.Action.REGISTER;

      const pilotRequest = new PilotRequest();
      pilotRequest.setUtime(utimeNow());
      pilotRequest.setAction(action);
      pilotRequest.setClientId(clientId);
      pilotRequest.setClientType(ClientType.Enum.WEB);

      const requestState: SkybusPilotRequestState = {
        status: "inflight",
        clientId,
        startTime: Date.now(),
      };
      if (deviceType === RemoteDeviceType.DOCK) {
        state.pilotRequest.skybusDock = requestState;
      } else {
        state.pilotRequest.skybus = requestState;
      }
      skybus.publish(ProtocolChannelDefinitions.PILOT_REQUEST, pilotRequest, {
        qos: QOS.UNRELIABLE,
      });
    },
    handleRequestPilotError(state, { payload }: PayloadAction<SkybusPilotResponseErrorAction>) {
      const { error, deviceType } = payload;
      const requestState: SkybusPilotRequestState = { status: "error", error };
      if (deviceType === RemoteDeviceType.DOCK) {
        state.pilotRequest.skybusDock = requestState;
      } else {
        state.pilotRequest.skybus = requestState;
      }
    },
    handlePilotResponse(state, { payload }: PayloadAction<SkybusPilotResponseAction>) {
      const { pilotResponse, deviceType } = payload;
      const successState: SkybusPilotRequestState = { status: "success", data: pilotResponse };

      /**
       * we only care about responses to a request we've made; ie. responses for the same clientId
       * after we've made a request. Note that this check isn't perfect, since if we made a flight
       * deck call with the same clientId, a pilot response message would also be generated.
       */
      const isResponseExpected = (requestState: SkybusPilotRequestState) =>
        requestState.status === "inflight" && requestState.clientId === pilotResponse.clientId;

      if (deviceType === RemoteDeviceType.DOCK) {
        if (isResponseExpected(state.pilotRequest.skybusDock)) {
          state.pilotRequest.skybusDock = successState;
        }
      } else {
        if (isResponseExpected(state.pilotRequest.skybus)) {
          state.pilotRequest.skybus = successState;
        }
      }
    },
    handleFlightDeckPilotRequestUpdate(
      state,
      { payload: requestState }: PayloadAction<FlightDeckPilotRequestState>
    ) {
      state.pilotRequest.flightDeck = requestState;
    },
    updateCaptureSettings(state, { payload }: PayloadAction<SkybusUpdateCaptureSettingsAction>) {
      const { skybus, captureSettings } = payload;

      state.captureSettingsRequest = { status: "inflight" };
      skybus.publish(UPDATE_CAPTURE_SETTINGS_PB, captureSettings);
    },
    handleCurrentCaptureSettings(
      state,
      { payload: captureSettings }: PayloadAction<CaptureSettings.AsObject>
    ) {
      // For vehicles on rel-9 and later, the camera service generates a new validation id whenever
      // capture settings are updated - any requests to update capture settings that don't match
      // the current validation id are rejected, and if we see the validation id has changed it
      // means that the vehicle has responded to an update.
      if (captureSettings.validationId) {
        if (captureSettings.validationId !== state.captureSettings?.validationId) {
          state.captureSettingsRequest = { status: "success" };
          state.captureSettings = captureSettings;
        }
        // For pre-rel-9 vehicles without validation id
      } else {
        // If we haven't gotten any settings before then we can initialize them
        if (state.captureSettingsRequest.status === "idle") {
          state.captureSettingsRequest = { status: "success" };
          state.captureSettings = captureSettings;
        } else if (state.captureSettingsRequest.status === "inflight") {
          if (state.captureSettings) {
            // Update to the most recently received utime
            state.captureSettings.utime = captureSettings.utime;
          }
          // Theoretically we should only be sending a capture settings request when we change
          // something in the UI, so we only acknowledge a response if something has changed from the
          // prior state. This ensures that we don't run into the race condition of sending a request,
          // then receiving a subscription message (which we interpret as a response) before the
          // vehicle has had a chance to process the request.
          if (!_.isEqual(state.captureSettings, captureSettings)) {
            state.captureSettingsRequest = { status: "success" };
            state.captureSettings = captureSettings;
          }
        }
      }
    },
    handleUpdateCaptureSettingsError(state, { payload: error }: PayloadAction<string>) {
      state.captureSettingsRequest = { status: "error", error };
    },
    handleCurrentIrCaptureSettings(
      state,
      { payload: irCaptureSettings }: PayloadAction<IrCaptureSettings>
    ) {
      state.irCaptureSettings = irCaptureSettings;
    },
    setWantsAutoPrep(state, { payload: wantsAutoPrep }: PayloadAction<boolean>) {
      state.wantsAutoPrep = wantsAutoPrep;
    },
    setSkillOverride(state, { payload: skillOverride }: PayloadAction<string>) {
      state.skillOverride = skillOverride;
    },
    clearSkillOverride(state) {
      state.skillOverride = undefined;
    },
    handleCurrentQcaStats(state, { payload: stats }: PayloadAction<QcaStatsLite.AsObject>) {
      state.qcaStats = stats;
      state.qcaStatsLastUpdated = new Date();
    },
    handleRelocalizationInfo(
      state,
      { payload: relocalizationInfo }: PayloadAction<RelocalizationInfo.AsObject>
    ) {
      state.relocalizationInfo = relocalizationInfo;
    },
    handleDockClientStatus(
      state,
      { payload: dockClientStatus }: PayloadAction<DockClientStatus.AsObject>
    ) {
      state.dockClientStatus = dockClientStatus;
    },
    handleG47MotorState(state, { payload: g47MotorState }: PayloadAction<G47MotorStatus.AsObject>) {
      state.g47MotorState = g47MotorState;
    },
    handleG47LedState(state, { payload: g47LedState }: PayloadAction<G47LedState.AsObject>) {
      state.g47LedState = g47LedState;
    },
    handleGroundhawkG47MotorStatuses(
      state,
      { payload: groundhawkG47MotorStatuses }: PayloadAction<GroundhawkG47MotorStatuses.AsObject>
    ) {
      state.groundhawkG47MotorStatuses = groundhawkG47MotorStatuses;
    },
    setVehicleInRoom(
      state,
      {
        payload: { vehicleSid = "", inRoom },
      }: PayloadAction<{ vehicleSid?: string; inRoom: boolean }>
    ) {
      state.vehicleSid = vehicleSid;
      state.vehicleInRoom = inRoom;
    },
    setDockInRoom(
      state,
      { payload: { dockSid = "", inRoom } }: PayloadAction<{ dockSid?: string; inRoom: boolean }>
    ) {
      state.dockSid = dockSid;
      state.dockInRoom = inRoom;
    },
    handleDockCameraStatus(state, action: PayloadAction<G47CameraStatus>) {
      state.dockCameraStatus = action.payload;
    },
    handleWeatherStationData(
      state,
      { payload: weatherStationData }: PayloadAction<WeatherStationData.AsObject>
    ) {
      state.weatherStationData = weatherStationData;
      state.weatherStationDataLastUpdated = new Date();
    },
    addAlert: (
      state,
      { payload }: PayloadAction<{ alertId: AlertId; alertVariant?: string; vehicleId: string }>
    ) => {
      const { alertId, alertVariant, vehicleId } = payload;
      if (state.activeAlerts[alertId] != null) {
        // There's already an ongoing alert of this type, do not fire it again
        return;
      }
      const newAlertInstance = {
        alert: alertId,
        alertVariant,
        createdAt: new Date().toISOString(),
        id: alertId,
        vehicleId,
      };

      state.activeAlerts[alertId] = newAlertInstance;
    },
    dismissAlert: (state, { payload }: PayloadAction<AlertId>) => {
      if (state.activeAlerts[payload]) delete state.activeAlerts[payload];
    },
    toggleMuteOnAlert: (state, { payload }: PayloadAction<AlertId>) => {
      const alertInstance = state.activeAlerts[payload];
      if (alertInstance) {
        alertInstance.mutedAt = alertInstance.mutedAt != null ? null : new Date().toISOString();
      }
    },
    setAlertPlayedAt: (
      state,
      { payload }: PayloadAction<{ alertId: AlertId; playedAt: string }>
    ) => {
      const alertInstance = state.activeAlerts[payload.alertId];
      if (alertInstance) {
        if (alertInstance.audioPlayedAt == null) {
          alertInstance.audioPlayedAt = [];
        }
        alertInstance.audioPlayedAt.push(payload.playedAt);
      }
    },
    setAlertTypes: (state, { payload }: PayloadAction<typeof ALERT_TYPES>) => {
      state.alertTypes = payload;
    },
    dismissAllAlerts: state => {
      state.activeAlerts = {};
    },
    handleLTEStats(state, { payload: lteStats }: PayloadAction<SignalStats.AsObject>) {
      state.lteStats = lteStats;
    },
    setIsAlertManagerDisplayActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isAlertManagerDisplayActive = payload;
    },
    toggleControlsModal: state => {
      state.showControlsModal = !state.showControlsModal;
    },
    setShowSettingsModal: (
      state,
      { payload }: PayloadAction<{ visible: boolean; tab?: SettingsModalTabsKey }>
    ) => {
      state.showSettingsModal = payload.visible;
      if (payload.visible) {
        state.settingsModalActiveTab = payload.tab ?? SETTINGS_MODAL_DEFAULT_TAB;
      }
    },
    setSettingsModalActiveTab: (state, { payload }: PayloadAction<SettingsModalTabsKey>) => {
      state.settingsModalActiveTab = payload;
    },
    setSettingsPreviewDismissed: (state, { payload }: PayloadAction<boolean>) => {
      state.settingsPreviewDismissed = payload;
    },
    handleVehicleWifiStatus(state, { payload }: PayloadAction<WifiStatus.AsObject>) {
      state.vehicleWifiStatus = payload;
    },
    setSelectedEntity: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedEntity = payload;
    },
    setHasTriedToRequestPilot(state, { payload }: PayloadAction<boolean>) {
      state.pilotRequest.hasTriedToRequestPilot = payload;
    },
    setReportedPageNavigationType(state, { payload }: PayloadAction<string>) {
      state.reportedPageNavigationType = payload;
    },
    setShowMarkersSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.showMarkersSidebar = payload;
    },
    setShowPerFlightFeedback(state, { payload }: PayloadAction<boolean>) {
      state.showPerFlightFeedback = payload;
    },
    handleDeviceNodePingFromServer(state, { payload }: PayloadAction<Ping>) {
      state.latestDeviceNodePingMessage = payload;
    },
    addDeviceNodeRTT(state, { payload }: PayloadAction<number>) {
      state.lastTenDeviceNodeRTTs.push(payload);
      if (state.lastTenDeviceNodeRTTs.length > 10) {
        state.lastTenDeviceNodeRTTs.shift();
      }
    },
    updateParachuteModalVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.showParachuteModal = payload;
    },
    updateNumParachutePresses: (state, { payload }: PayloadAction<number>) => {
      const updatedNum = Math.min(payload, 3);
      state.numParachutePresses = updatedNum;
    },
    handleAllowChannelConflictAlert: (state, { payload }: PayloadAction<boolean>) => {
      state.allowChannelConflictAlert = payload;
    },
    handleChannelConflict: (state, { payload }: PayloadAction<boolean>) => {
      state.hasChannelConflict = payload;
    },
    updateCurrentOtherUsedSiteChannels: (state, { payload }: PayloadAction<number[]>) => {
      state.currentOtherUsedSiteChannels = payload;
    },
  },
});

export { actions as teleopActions };

export default reducer;
